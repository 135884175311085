.patientContainer {
  text-align: center;
  position: relative;
  top: 50%; left: 50%;
  width: 100%;
  transform: translate(-50%,-50%);
  margin-bottom: 15px;
}

.patientTypeContainer {
  margin-bottom: 35px;
}

.mapMarkerIcon {
  margin-top: 10px;
  margin-bottom: 10px;
}

.locationDescriptionTwo {
  margin-top: 0px !important;
  font-size: 21px;
  color: #707070;
}

.patientHeader {
  color: #707070;
}

.locationContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.fa-map-marker-alt {
  margin-top: 10px;
  margin-bottom: 10px;
}

.locationNumber {
  margin-bottom: 10px;
  font-size: 18px;
  color: #707070;
  text-decoration: underline;
}

.locationNumber a {
  color: #707070 !important;
}

.locationContainer {
  margin-bottom: 15px;
}

.locationAddress {
  margin-top: 5px;
  margin-bottom: 5px;
}

.locationAddressErr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.locationDescription {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #707070;
}
.locationDescriptionTwo {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #707070;
  font-size: 18px;
}

.patientTypeContainer {
  top: 33%;
  height: 30%;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.locationTypeName {
  font-size: 21px;
  font-weight: 500;
  color: #707070;
}

.description {
  font-size: 22px;
  color: #585858;
  font-weight: bold;
}

.newPatientButton {
  width: 45%;
  margin-right: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.locationAddress {
  font-size: 18px;
  color: #707070;
}

.locationAddressErr {
  font-size: 18px;
  color: #707070;
}

.returningPatientButton {
  width: 45%;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 625px) {
  .patientContainer {
    height: 100%;
    text-align: center;
    position: relative;
    top: 58%; left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    margin-bottom: 15px;
  }

  .locationAddress {
    font-size: 18px;
  }

  .locationAddressErr {
    font-size: 18px;
  }

  .locationNumber {
    margin-top: 5px;
    font-size: 18px;
  }

  .patientTypeContainer {
    margin-bottom: 20px !important;
  }

  .newPatientButton {
    width: 90%;
    margin-right: 0px;
  }

  .returningPatientButton {
    width: 90%;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 320px) {
  .patientContainer {
    height: 100%;
    text-align: center;
    position: relative;
    top: 58%; left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    margin-bottom: 15px;
  }
}

// @media only screen and (max-height: 420px) {
//   .patientContainer {
//     height: 100%;
//     text-align: center;
//     position: relative;
//     top: 0%; left: 0%;
//     width: 100%;
//     transform: translate(0%, 0%);
//     margin-bottom: 15px;
//   }
// }
