.schedContainer {
  text-align: center;
  position: relative;
  width: 100%;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.schedInnerContainer {
  padding: 0 !important;
}


.false {
  background-color: white;
}

.schedule_container {
  text-align: center;
}

.day_container {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  margin: 5px;
  min-width: 310px;
}

.prefHeader {
  font-weight: bold;
  font-size: 18px;
  color: #707070;
  margin-bottom: 10px;
  margin-top: 10px;
}

.day {
  border-radius: 10px;
  background-color: #707070;
  color: white;
  float: right;
  width: 30%;
  margin: 5px;
  min-width: 90px !important;
  min-height: 65px !important;
  border:0;
  font-size: 14px;
  white-space: nowrap !important;
  padding: 0px !important;
}

.day:active {
  padding: 0px !important;
}

.moon {
  padding-bottom: 0px !important;
}

.sunrise {
  padding-bottom: 4px;
}

.morningText {
  position: relative;
  bottom: -3px;
}

.eveningText {
  position: relative;
  bottom: -4px;
}

.disabled {
  pointer-events: none;
  background-color: #D0D0D0;
}
.day_times {
  display: inline-flex;
  text-align: center;
  padding: 0 !important;
  margin-bottom: 10px;
  justify-content: center;
}

.dayButton {
  margin: auto;
  margin-top: 5px;
  display: block;
  pointer-events:none;
}

.day_name {
  color: #707070;
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
}

.continue_button {
  width: 70%;
  margin: 15px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 960px) {
  .day_container{
    max-width: 100% !important;
    width: 100%;
    flex: 100% !important;
  }
  .schedContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 4%; left: 0%;
    transform: translate(0%, 0%);
  }
}

@media only screen and (max-height: 700px) {
  .schedContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 5%; left: 0%;
    transform: translate(0%, 0%);
  }
}
