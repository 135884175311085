.footerContainer {
  position: fixed;
  bottom: 0px;
  width: 67%;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  max-height: 25px;
  min-height: 20px;
  text-transform: capitalize;
  z-index: 999;
  padding-top: 2px;
}

.footerAddress {
  text-align: center;
  width: 95%;
  overflow: hidden;
}

@media only screen and (max-width: 480px) {

  .footerContainer {
    width: 100%;
  }
}
