.locationsList {
  text-align: center;
  position: relative;
  width: 100%;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  margin-bottom: 15px;
  max-height:100%;
}

.location {
  border-radius: 10px;
  margin: 15px !important;
  min-height: 200px;
}

.locationHeader {
  font-size: 24px;
  font-weight: bold;
  color: #707070;
}

.locationAddress {
  text-transform: capitalize;
}

.locationsDescription {
  font-size: 16px;
  color: #707070;
}

.locationName {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #262626;
}

.locationButton {
  border: none !important;
  margin-bottom: 10px;
  left: 5%;
  height: 36px !important;
  width: 90% !important;
  position: absolute !important;
  bottom: 0px !important;
  font-weight: bold !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 480px) {
  .locationsList {
    top: 10%;
  }

  .locationPad {
    padding-bottom: 50px;
  }

  .locationsList {
    text-align: center;
    position: relative;
    width: 100%;
    top: 5%; left: 0%;
    transform: translate(0%, 0%);
  }
}
