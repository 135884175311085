body, html {
  margin: 0;
  padding: 0;
  font-family: Heebo, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  outline: 0 !important;
  outline-offset: 0  !important;
  background-image: none  !important;
  -webkit-box-shadow: none !important;
  box-shadow: none  !important;
}

.provider {
  text-align: center;
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  overflow-y: scroll;
  grid-area: 1 / 2 / 2 / 4;
}

.row {
  margin: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.btn:focus,.btn:active {
   outline: none !important;
   box-shadow: none;
}

.providerContainer {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

button {
   outline: none;
}

button::-moz-focus-inner {
   border: 0;
}

button:focus {
  outline:none;
}


button:disabled {
  opacity: 0.45 !important;
  cursor: default !important;
  pointer-events: none !important;
}

#continueButton:disabled {
  opacity: 0.45 !important;
  cursor: default !important;
  pointer-events: none !important;
}

.btn-primary, .btn-outline-primary {
  height: 50px;
}

.safari.keyboard .app-container {
  bottom: 270px;
}

@media only screen and (max-width: 480px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 17% 83%;
    grid-template-areas: "div1" "div2";
  }

  .provider {
    grid-area: div1;
  }

  .div2 {
    display: flex;
    overflow-y: scroll;
    grid-area: div2;
    margin-top: 1px;
    margin-bottom: 1em;
  }
}
