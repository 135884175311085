.createAppointmentContainer {
  text-align: center;
  position: relative;
  width: 100%;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}

.createContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.returnToSite {
  margin: 10px;
}

.createAppointmentHeader {
  margin-top: 25px;
  font-size: 21px;
  color: #707070;
}

.createErrorHeader {
  font-size: 21px;
  font-weight: bold;
  color: #707070;
  margin-top: 25px;
  margin-bottom: 10px;
}

.createErrorDescription {
  font-size: 21px;
  color: #707070;
  margin-bottom: 10px;
}

.createAppointmentDescription {
  font-size: 21px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 10px;
}

.createAppointmentDescriptionTwo {
  font-size: 21px;
  color: #707070;
  margin-bottom: 25px;
}

.createAppointmentImage {
  margin-bottom: 10px;
}

.scheduleNewAppt {
  background-color: white !important;
  min-height: 40px;
  max-height: 70px;
  margin-bottom: 25px;
}

.appointmentProvider {
  font-size: 16px !important;
  margin-bottom: 25px;
}

.createAppointmentHead {
  color: #707070;
  font-weight: bold;
  font-size: 26px !important;
}

.createAppointmentHeadLength {
  font-size: 14px;
  font-style: italic;
}

.createAppointmentTimes {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 7px;
  margin-top: 10px;
}

.returnToSite {
  background-color: white !important;
  min-height: 65px;
  max-height: 70px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 480px) {
  .createAppointmentContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
  }

  .scheduleNewAppt {
    font-size: 4.2vw !important;
  }

  .returnToSite {
    font-size: 4.2vw !important;
  }
}
