.scheduleAppointmentContainer {
  text-align: center;
  position: relative;
  width: 100%;
  top: 50%;
  vertical-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
}

.createLoader {
  padding-top: 15%;
  margin-top: 15%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}

.radio {
  text-align: left;
  display: block;
  width: 100%;
  padding: 5px;
}

#radio-questions {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding-left: 5px;
  padding-top: 8px;
}

#radio-questions-title {
  color: #707070;
  text-align: left;
  padding-right: 10px;
}

.selectpicker {
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.codeAlert {
  width: 100%;
  margin-top: 12px;
  font-size: 13px !important;
  color: #707070;
}

.insuranceMessage {
  font-size: 16px;
  font-weight: 700;
  color: #707070;
}

.show {
  display: block;
}

.schedule_appointment_header {
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 10px;
}

input[type=radio] {
  width: 30px;
  transform: scale(0.75);
  height: 30px;
}

#radioSelectLabel {
  padding-top: 3px;
  position: relative;
  width: 85%;
  vertical-align: top;
}

.field {
  margin-top: 10px;
}

.insuranceSelection {
  margin-top: 15px;
}

.insuranceMessage {
  margin-top: 25px;
  margin-bottom: 10px;
}

label {
  padding: 0px;
  margin-bottom: 0px !important;
}

#inputRadioButton {
  vertical-align: super;
}

.insurance {
  height: 40px;
  width: 40%;
  margin: 10px;
  border-radius: 5px;
}

.scheduleApptButton {
  margin-bottom: 50px;
}

.label {
  color: #707070;
}

.insuranceDescription {
  margin-bottom: 20px;
  font-weight: bold;
  color: #707070;
  font-size: 17px !important;
}

.appointmentHeadLength {
  font-style: italic;
  color: #707070;
  font-size: 14px;
}

.selected {
    background-color: #2699fb !important;
    color: white !important;
}

.dobLabel {
  text-align: left !important;
  padding: 0px !important;
  font-weight: 500;
  font-size: 16px;
}

.appointmentHead {
  font-size: 18px;
  color: #707070;
}

#otherInsuranceLabel {
  margin-top: 15px;
  padding-bottom: 5px;
}

.appointmentTimes {
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #707070;
  white-space: nowrap;
}

.schedApptProvider {
  font-size: 15px;
  color: #707070;
  margin-bottom: 10px;
}

.insuranceContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;

}

.dobDiv{
  display: flex;
  -webkit-display:flex;
  justify-content: center;
  -webkit-justify-content:center;
}

.monthSelect{
  min-width: 125px;
  max-width: 50%;
  flex:3;
  -webkit-flex:3;
}

.dayInput {
  min-width: 52px !important;
  flex: 1;
  -webkit-flex: 1;
  text-align: center;
  margin-left: 7px;
}

.center{
  text-align:center;
}

.invalid {
  border: 1px solid red !important;
}

.scheduleApptImage {
  margin-top: 10px;
  margin-bottom: 5px;
  min-width: 35px;
  min-height: 35px;
  object-fit: contain;
}
.yearInput {
  min-width: 100px !important;
  max-width:30%;
  text-align: center;
  margin-left:7px;
  flex:2;
  -webkit-flex:2;
}

.insContainer {
  margin-top: 1.5em;
}

.css-26l3qy-menu {
  top: auto !important;
  bottom: 100% !important;
}

.insuranceInput {
  background-color: none !important;
}


.scheduleApptButton {
  height: 40px;
  white-space: nowrap;
}

@media only screen and (max-width: 880px) {
  .scheduleAppointmentContainer {
    text-align: center;
    position: relative;
    width: 100%;
    margin-top: 15px;
    top: 5%;
    left: 0%;
    transform: translate(0%, 0%);
  }

  .codeAlert {
    width: 100%;
    margin-top: 12px;
    font-size: 13px;
    color: #707070;
  }
}

@media only screen and (max-width: 480px) {
  .scheduleAppointmentContainer {
    text-align: center;
    position: relative;
    width: 100%;
    margin-top: 15px;
    top: 5%;
    left: 0%;
    transform: translate(0%, 0%);
  }
}

@media only screen and (max-width: 375px) {
  .appointmentTimes {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 325px) {
  .appointmentTimes {
    font-size: 16px !important;
  }
}


@media only screen and (max-height: 728px) {
  .scheduleAppointmentContainer {
    text-align: center;
    position: relative;
    width: 100%;
    margin-top: 15px;
    top: 5%;
    left: 0%;
    transform: translate(0%, 0%);
  }
}

@media only screen and (max-height: 1280px) {
  .scheduleAppointmentContainer {
    text-align: center;
    position: relative;
    width: 100%;
    margin-top: 15px;
    top: 5%;
    left: 0%;
    transform: translate(0%, 0%);
  }
}
