.practiceName {
  margin-top: 5px;
  font-size: 22px !important;
  font-weight: bold;
}

.floatLeft {
  z-index: 999;
  position: absolute !important;
  top: 2vh;
  left: 35vw !important;
}

.hideButton {
  display: none !important;
}

.providerName {
  font-size: 18px !important;
  width: 100%;
  font-weight: 500;
  overflow-wrap: break-word;
  padding: 0px !important;
  margin: 0px !important;
  margin-top: 5px !important;
}

.providerImageSideBar {
  min-height: 40px;
  max-height: 250px;
  margin-top: 5px;
  max-width: 250px;
  min-width: 40px;
  height: 100%;
  object-fit: cover;
}

.provider {
  width: 100%;
  height: 100%;
  z-index: -1;
  display: block;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom,white,white);
  opacity: .5;
}

.providerHead {
  position: relative !important;
}

.logo {
  width: 100% !important;
  min-width: 25px !important;
  min-height: 25px !important;
  max-height: 275px;
  max-width: 275px;
  object-fit: contain;
}

.providerContainer {
  width: 100%;
  height: 50%;
  display: inline-block;
  position: absolute !important;
  text-align: center;
  z-index: 1;
  margin: 0 !important;
  padding: 0 !important;
}

.logo-radius {
  margin-top: 5px;
  border-radius: 10px;
  padding: 0.75em;
  padding-bottom: 0.75em !important;
  padding-top: 0.75em !important;
}

.border-radius {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
}

.logoWrapper {
  width: 100% !important;
  max-height: 250px;
  min-height: 25px !important;
  min-width: 25px !important;
}

.prContainer {
  height: auto;
  position: relative;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -25%);
  transform: translate(-50%, -25%);
}

.backButton {
  cursor:pointer;
  color:#707070 !important;
}

.backButtonSvg {
  filter: invert(44%) sepia(9%) saturate(8%) hue-rotate(317deg) brightness(95%) contrast(80%);
}

@media only screen and (max-width: 480px) {
  .logo {
    width: auto !important;
    min-width: 25px !important;
    min-height: 50px !important;
    width: 100% !important;
    height: 80%;
    margin-top: 10%;
  }

  .floatLeft {
    position: absolute !important;
    top: 3vh;
    left: 5px !important;
  }

  .backButton {
    color: #707070 !important;
  }

  .backButtonSvg {
    filter: invert(15%) sepia(9%) saturate(8%) hue-rotate(317deg) brightness(95%) contrast(80%);
    background-color: white;
    border-radius: 50%;
  }

  .prContainer {
    height: 100%;
    position: relative;
    left: 0%;
    top: 0%;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }

  .logoWrapper {
    max-height: 100% !important;
    min-width: 25px !important;
    min-height: 25px !important;
    height: 60%;
    margin: auto;
  }

  .logo-radius {
    margin-top: 0.8em;
    border-radius: 10px;
    padding: 0.75em;
    padding-bottom: 0.25em !important;
    padding-top: 0 !important;
  }

  .providerImageSideBar {
    object-fit: cover;
    height: 100%;
  }

  .practiceName {
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0.45em;
    margin-top: -0.15em;
    font-size: 18px !important;
  }

  .providerName {
    font-size: 16px !important;
  }

  .providerContainer {
    height: 100%;
    width: auto;
  }
}

@media only screen and (max-width: 320px) {
  .logo-radius {
    margin-top: 7px;
  }
}
