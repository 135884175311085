.firstApptContainer {
  text-align: center;
  position: relative;
  width: 100%;
  top: 49%; left: 50%;
  transform: translate(-50%,-50%);
}

.firstAppointmentContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
  max-height: 94%;
  position: relative;
}

.firstAppointmentHeader {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #707070;
}

.firstAppointmentDescription {
  font-size: 21px;
  color: #707070;
  font-weight: bold;
  margin-bottom: 20px;
  white-space: nowrap;
}

.firstAppointmentDescriptionTwo {
  font-size: 18px;
  color: #707070;
  margin-top: 15px;
}

.firstAppointmentMoreOptions {
  margin-bottom: 35px;
  padding: 0 !important;
}

.firstAppointmentSchedule {
  margin-bottom: 15px;
  margin-top: 25px;
}

@media only screen and (max-width: 375px) {
  .firstAppointmentDescription {
    font-size: 17px !important;
  }
}

@media only screen and (max-width: 325px) {
  .firstAppointmentDescription {
    font-size: 16px !important;
  }
}

@media only screen and (max-height: 415px) {
  .firstApptContainer {
    top: 3%; left: 0%;
    transform: translate(0%,0%);
  }
}
