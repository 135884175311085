.modalContainer {
  position: absolute;
  text-align: center;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 45vw;
  height: 320px;
  background-color: #f1f1f1;
  border-radius: 10px;
  z-index: 9999;
  box-shadow: 0 10px 25px 0 rgba(0,0,0,0.3);
}

.codeInput {
  height: 50px !important;
  margin-bottom: 15px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.loaderAppt {
  position: relative;
  top: 155px;
}

.apptErrMsg {
  font-size: 10px;
  margin-top: 3px;
  color: red;
}

.invalid {
  border: 1px solid red !important;
}

.verificationDescription {
  font-weight: bold;
  color: #707070;
  font-size: 18px;
  margin-top: 15px;
}

.verifyButtons {
  margin: 10px;
  height: 38px !important;
}

.didntReceiveCode {
  border: none !important;
  background-color: transparent !important;
  color: #707070 !important;
  margin-bottom: 20px;
  text-decoration: underline;
}

@media only screen and (max-width: 880px) and (min-width: 481px) {
  .verifyButtons {
    white-space: nowrap;
    margin: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .modalContainer {
    width: 95vw !important;
  }

  .verifyButtons {
    white-space: nowrap;
    margin: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
  }
}
