.apptContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
  text-align: center;
  position: relative;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  overflow-y: auto;
  max-height: 90%;
}

.apContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.appointmentTypeTime {
  color: #707070;
  font-size: 16px;
  font-style: italic;
}

.appointmentRow {
  height: 100%;
  max-height:100%;
}

.appointmentTypeImage {
  margin: 5px;
  min-width: 35px;
  min-height: 35px;
  width:45px !important;
  height:45px !important;
  object-fit: contain;
}

.appointmentsHeader {
  font-size: 22px;
  font-weight: bold;
  color: #707070;
  padding-top: 20px;
}

.appointmentsDescription {
  color: #707070;
  font-size: 18px;
  margin-bottom: 15px;
}

.appointmentType {
  line-height: 1.3 !important;
  color: #707070;
  font-size: 18px;
  font-weight: bold;
  overflow-wrap: nowrap;
  text-transform: capitalize;
}

.alignIems {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  max-height: 100%;
  width: 90%;
}

.appointmentContainer {
  border-radius: 10px;
  width: 100%;
  min-height: 140px;
  min-width: 175px;
  margin: 10px;
  text-align: center;
  position: relative;
  margin-bottom: 25px;
  cursor: pointer;
}

.appointmentTime {
  background-color: white !important;
}

@media only screen and (max-width: 480px) {
  .apptContainer {
    top: 5%; left: 4%;
    transform: translate(0%, 0%);
    max-height: 70vh;
  }
}

@media only screen and (max-height: 530px) {
  .apptContainer {
    top: 5%; left: 4%;
    transform: translate(0%, 0%);
  }
}
