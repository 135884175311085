.apptOptionsContainer {
  text-align: center;
  position: relative;
  width: 100%;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0%;
}

.optionsTwoLoader {
  text-align: center;
  position: relative;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
}

.appointmentTime {
  height: 60px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.appointmentTime:hover {
  color: white !important;
  background-color: #2699FB !important;
}

.apptHeader {
  font-size: 24px;
  font-weight: bold;
  color: #707070;
}

.apptDescription {
  color: #707070;
  margin-bottom: 15px;
}

.moreOptions {
  margin-top: 15px;
  background-color: #2699FB !important;
}

.margin{
  margin-bottom: 2em !important;
}

@media only screen and (max-height: 550px) {
  .apptOptionsContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 0%; left: 0%;
    margin-top: 5%;
    transform: translate(0%, 0%);
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 375px) {
  .appointmentOptionsContainer {
    margin-bottom: 10%;
  }

  .apptOptionsContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 0%; left: 0%;
    margin-top: 5%;
    transform: translate(0%, 0%);
    margin-bottom: 10%;
  }
}
