.providersContainer {
  min-height: 120px;
  height: 13vh;
  border-radius: 10px;
  margin: 30px;
  margin-top: 5%;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 20px;
  position: relative;
  cursor:pointer;
  color: #707070;
}

.providersHeader {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: bold;
  color: #707070;
  margin-top: 10px;
}

.provSelectHeader {
  font-size: 21px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 8px;
}

.locationsNumber {
  margin-bottom: 10px;
  font-size: 18px;
  color: #707070;
  text-decoration: underline;
}

.locationsNumber a {
  color: #707070 !important;
}


.callUsHeader {
  font-size: 21px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 8px;
}

.appointmentTypeDescription {
  font-size: 14px;
  color: #707070;
  margin-top: 10px;
}

.providerScroll {
  max-height: 200px !important;
  overflow-y: scroll;
}

.appointmentProviderContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.appointmentProviderType {
  text-transform: capitalize;
  font-size: 21px;
  font-weight: bold;
  color: #707070;
}

.providersAppointmentHeader {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
  color: #707070;
}

.appointmentTypeProviderTime {
  font-style: italic;
  color: #707070;
  font-size: 14px;
}

.provContainer {
  text-align: center;
  position: relative;
  width: 100%;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}

.imageContainer {
  text-align: center;
}

.providersName {
  position: absolute;
  top: 40%; left: 50%;
  transform: translate(-50%, 10%);
  padding-top: 15px;
  font-size: 21px;
  font-weight: bold;
  overflow-wrap: break-word;
  width: 100%;
}

.locationsDescription {
  margin-bottom: 15px;
}

.providerImage {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  top: 60%; left: 50%;
  transform: translate(-50%,-60%);
}

.changeAppointmentButton {
  margin-top: 20px;
}

.findTimeButton {
  font-size: 16px !important;
}

@media only screen and (max-width: 780px) {
  .provContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 50%; left: 50%;
    overflow-y: auto !important;
    transform: translate(-50%,-50%);
    margin-bottom: 10%;
  }

  .providersName {
    position: absolute;
    top: 21%; left: 50%;
    transform: translate(-50%, 10%);
    padding-top: 35px;
  }

  .providersContainer {
    height: 11vh;
  }

  .providerImage {
    border-radius: 100px;
    height: 80px;
    width: 80px;
  }

  .providersHeader{
    margin-bottom:50px;
  }

}

@media only screen and (max-width: 480px) {
  .provContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 5%;
    left: 0%;
    overflow-y: auto !important;
    transform: translate(0,0);
    margin-bottom: 10%;
  }
}

@media only screen and (max-height: 700px) {
  .provContainer {
    text-align: center;
    position: relative;
    width: 100%;
    top: 5%;
    left: 0%;
    padding-bottom: 25px;
    overflow-y: auto !important;
    transform: translate(0,0);
    margin-bottom: 10%;
  }
}
