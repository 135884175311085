.pickerContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.availAppointments {
  background-color: #f5f5f5;
  border-radius: 10px;
  margin: 10px;
  height: 30vh;
}

#availTimes {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selectedDateTitle {
  color: #707070;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.timesContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 10px;
  margin-left: 25px;
  padding: 20px;
  width: 100%;
  margin-right: 10px;
  max-width: 300px;
  min-width: 300px !important;
  max-height: 350px;
  min-width: 310px !important;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.react-calendar__navigation__arrow, .react-calendar__navigation__next-button svg {
  fill: #707070 !important;
}

.react-calendar__navigation__arrow, .react-calendar__navigation__next-button {
  border-radius: 50% !important;
}

.apptTimes {
  line-height: 1.25 !important;
  width: 85% !important;
  min-height: 35px !important;
}

.dow {
  margin: 10px;
}

.pickerTop {
  display: inline-flex;
}

.week {
  display: inline-flex;
}

.weekspan {
  padding-top: 7px;
}

@media only screen and (max-width: 780px) {
  .calendar {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .title, .subtitle {
    display: none;
  }

  .react-calendar__month-view__days, /* Container around all days */
  .react-calendar__month-view__weekdays { /* Container around weekday labels */
    grid-gap: 8px !important;
  }

  .timesContainer {
    margin-top: 25px;
    margin-left: 0px;
    padding: 20px;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10%;
  }
  .title, .subtitle {
     display: none;
  }

}
